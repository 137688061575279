import { type ReactNode } from "react";
import useAuth from "../hooks/useAuth";
import LoginForm from "./LoginForm";
import FullscreenProgress from "../util/FullscreenProgress";

export default function AuthWall({ children }: { children: ReactNode }) {
    const authState = useAuth()
    if (authState === 'loading')
        return <><FullscreenProgress /></>
    else if (authState === 'unauthenticated')
        return <><LoginForm /></>
    else
        return <>{children}</>
}