import './App.css';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CardContent, Chip, Container, Divider, Link, Stack, Typography, useTheme } from '@mui/material';
import { ChevronRight, ExpandMore, Person, Phone, Photo, PinDrop, PriorityHigh } from '@mui/icons-material';
import HSpacer from './util/HSpacer';
import If from './util/If';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
)

type Job = { id: number, priority?: boolean, customer: string, contactName?: string, estimate: string, city: string, phone: string, attachments: number, notes: string }

function JobCard(props: Job) {
  let theme = useTheme()
  return <Card variant='outlined'>
    <CardActionArea>
      <CardContent>
        <Stack direction='row'>
          <Stack direction='column' spacing={1}>
            <Stack direction='row' spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <If condition={props.priority === true}>
                <Chip variant='outlined' color='error' label={<PriorityHigh fontSize='small' />} />
              </If>
              <Typography variant='h6'>
                {props.customer} {bull} {props.estimate}
              </Typography>
              <HSpacer />
            </Stack>
            <Stack direction="row" spacing={1} sx={{ ...theme.typography.body2, justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }} useFlexGap divider={<Divider orientation='vertical' variant='fullWidth' flexItem />}>
              <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <PinDrop fontSize='small' />
                <Typography fontSize='inherit'>{props.city}</Typography>
              </Stack>
              {props.contactName && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Person fontSize='small' />
                <Typography fontSize='inherit'>{props.contactName}</Typography>
              </Stack>}
              <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Phone fontSize='small' />
                <Link href={`tel:+1${props.phone.replaceAll('-', '')}`}>{props.phone}</Link>
              </Stack>
              {props.attachments > 0 && <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <Photo fontSize='small' />
                <Typography fontSize='inherit'>{props.attachments}</Typography>
              </Stack>}
            </Stack>
            <If condition={!!props.notes}>
              <Typography color='textSecondary' fontStyle='italic'>
                {props.notes}
              </Typography>
            </If>
          </Stack>
          <HSpacer />
          <ChevronRight fontSize='large' sx={{ my: 'auto' }} />
        </Stack>
      </CardContent>
    </CardActionArea>
  </Card>
}

const jobs: Job[] = [
  {
    id: 1,
    priority: true,
    customer: 'Chase Cole',
    estimate: '$1700',
    city: 'Pearland',
    phone: '832-477-7530',
    attachments: 0,
    notes: 'Remove ash in bkyd.'
  },
  {
    id: 2,
    customer: 'Jordan Crowley',
    estimate: 'Hourly',
    city: 'Liverpool',
    phone: '979-236-8417',
    attachments: 0,
    notes: 'Fell pines, leave material, no cleanup'
  },
  {
    id: 3,
    customer: 'Gulf Coast Primitive Baptist Church',
    contactName: 'Greg Collier',
    estimate: '$1500',
    city: 'Alvin',
    phone: '979-480-4150',
    attachments: 3,
    notes: 'Cut and clean-leave material in back-skidsteer'
  },
  {
    id: 4,
    customer: 'Glen Finn',
    estimate: '$1200-1500',
    city: 'Santa Fe',
    phone: '713-858-8645',
    attachments: 1,
    notes: '3/4 day with skid and grapple truck'
  }
]

function App() {
  return (
    <Container maxWidth="lg" sx={{ my: 3 }}>
      <Accordion elevation={3} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack direction='row' spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Chip label={jobs.length} />
            <Typography variant='h5'>Active Jobs</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction='column' spacing={1}>
            {jobs.map(j => <JobCard key={j.id} {...j} />)}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={3}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Stack direction='row' spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Chip label="0" />
            <Typography variant='h5'>Closed Jobs</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>

        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default App;
