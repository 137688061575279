import { useEffect, useState } from "react"
import FirebaseApp from "../FirebaseApp"
import { getAuth, User } from 'firebase/auth'

export type AuthState = 'loading' | 'unauthenticated' | User

export default function useAuth() {
    const [user, setUser] = useState<AuthState>('loading')
    useEffect(() => {
        getAuth(FirebaseApp).onAuthStateChanged(u => setUser(u || 'unauthenticated'))
    }, [])
    return user
}