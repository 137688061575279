import FirebaseApp from "../FirebaseApp";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { Alert, AlertTitle, Container, Grow, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import HSpacer from "../util/HSpacer";
import { Login as LoginIcon } from '@mui/icons-material'

export default function LoginForm() {
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const login = useCallback(() => {
        let auth = getAuth(FirebaseApp)
        let email = (document.getElementById('email') as HTMLInputElement).value
        let password = (document.getElementById('password') as HTMLInputElement).value
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password).catch(() => setError(true)).finally(() => setLoading(false))
    }, [])
    return (
        <Container sx={{ padding: 0 }} maxWidth="sm">
            <Stack spacing={2} sx={{ padding: 6, position: 'relative', transform: 'translateY(50%)' }}>
                <Grow in={error} unmountOnExit>
                    <Alert severity="error" onClose={() => setError(false)}>
                        <AlertTitle>Error</AlertTitle>
                        Incorrect email or password - please try again.
                    </Alert>
                </Grow>
                <TextField disabled={isLoading} id="email" type="email" autoComplete="email" label="Email" />
                <TextField disabled={isLoading} id="password" type="password" autoComplete="current-password" label="Password" />
                <Stack direction="row">
                    <HSpacer />
                    <LoadingButton onClick={login} loading={isLoading} loadingPosition="start" startIcon={<LoginIcon />} size="large" variant="contained">{isLoading ? 'Logging in...' : 'Login'}</LoadingButton>
                    <HSpacer />
                </Stack>
            </Stack>
        </Container>
    )
}